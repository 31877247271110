import './line-item-list.scss'
import LineItem from '../LineItem/LineItem'
import type { Attribute, Cart } from '@/types/shopify'
import {
  isSubscriptionLineItem,
  getLineItemAttributeByKey,
} from '@/scripts/cart/utilities'
import { useState, type FC, useEffect, useMemo } from 'react'
import type { AdvancedCartItem } from '@/types/pages/cart'
import CartPopOverSummary from '../CartPopOverSummary/CartPopOverSummary'
import clsx from 'clsx'

interface LineItemListProps {
  cart: Cart
}

const addGiftItems = (
  cartItems: AdvancedCartItem[],
  giftItems: AdvancedCartItem[],
) => {
  cartItems.forEach((item) => {
    const giftItem = giftItems.find((gift) =>
      gift.attributes.find(
        (attr) =>
          attr.key === '_trigger_id' && attr.value === item.merchandise.id,
      ),
    )

    if (giftItem) {
      const index = cartItems.findIndex((cartItem) => cartItem.id === item.id)
      cartItems.splice(index + 1, 0, giftItem)
    }
  })

  return cartItems
}

const LineItemList: FC<LineItemListProps> = ({ cart }) => {
  const [primaryBag, setPrimaryBag] = useState<AdvancedCartItem[]>([])
  const [secondaryBag, setSecondaryBag] = useState<AdvancedCartItem[]>([])

  const groupLinesToBags = (cart: Cart) => {
    const groups = cart.lines.reduce(
      (
        categories: any,
        proxyLine: any,
        index: string | number,
        lines: any[],
      ): any => {
        const line = JSON.parse(JSON.stringify(proxyLine)) as AdvancedCartItem
        const { quantity } = line

        const isSubscriptionItem = isSubscriptionLineItem(line)

        const isProductBox =
          getLineItemAttributeByKey(line, '_is_product_box') === 'true'
        if (isProductBox) return categories

        const deliveryZoneId = getLineItemAttributeByKey(
          line,
          '_delivery_zone_id',
        )
        if (!deliveryZoneId) {
          return categories
        }

        const rawAddons = getLineItemAttributeByKey(line, '_addons')
        if (rawAddons) {
          const addonsData = JSON.parse(rawAddons)
          for (const row of addonsData) {
            const addonIndex = lines.findIndex((line) =>
              line.merchandise.id.endsWith(row.id),
            )
            if (addonIndex === -1) {
              console.error('Addon not found')
              continue
            }
            line.addons = line.addons || []
            line.addons.push({
              ...lines[addonIndex],
              _addonQuantity: row.quantity,
            })
            categories.recognizedQuantity[addonIndex] -=
              quantity * Number(row.quantity)
          }
        }

        const productBoxVariantId = getLineItemAttributeByKey(
          line,
          '_product_box',
        )
        if (productBoxVariantId) {
          const productBoxIndex = lines.findIndex((line) =>
            line.merchandise.id.endsWith(productBoxVariantId),
          )
          line.productBoxes = line.productBoxes || []
          line.productBoxes.push(lines[productBoxIndex])
          categories.recognizedQuantity[productBoxIndex] -= quantity
        }

        categories.recognizedQuantity[index] -= quantity

        const giftItem = line.attributes.find(
          (attr) => attr.key === '_gift' && attr.value === 'true',
        )

        if (giftItem) {
          categories.gifts.push(line)
          return categories
        }
        isSubscriptionItem
          ? categories.subscriptions.push(line)
          : categories.otps.push(line)
        return categories
      },
      {
        subscriptions: [] as AdvancedCartItem[],
        otps: [] as AdvancedCartItem[],
        gifts: [] as AdvancedCartItem[],
        recognizedQuantity: cart.lines.map(
          (line: { quantity: number }) => line.quantity,
        ),
      },
    ) as any

    groups.recognizedQuantity.forEach((quantity: number, index: number) => {
      if (quantity === 0) return
      const line = JSON.parse(JSON.stringify(cart.lines[index]))
      const isSubscriptionItem = isSubscriptionLineItem(line)
      const giftItem = line.attributes.find(
        (attr: Attribute) => attr.key === '_gift' && attr.value === 'true',
      )

      line.quantity = quantity
      if (giftItem) {
        groups.gifts.push(line)
        return
      }
      isSubscriptionItem
        ? groups.subscriptions.push(line)
        : groups.otps.push(line)
    })

    const hasSubscriptions = groups.subscriptions.length

    setPrimaryBag(
      hasSubscriptions
        ? addGiftItems(groups.subscriptions, groups.gifts)
        : addGiftItems(groups.otps, groups.gifts),
    )
    setSecondaryBag(
      hasSubscriptions ? addGiftItems(groups.otps, groups.gifts) : [],
    )
  }

  const { summaryClasses, summaryWithBorderClasses } = useMemo(() => {
    const summaryClasses = ['cart-pop-over__line-item-list__summary']
    const summaryWithBorderClasses = ['cart-pop-over__summary']
    if (
      (primaryBag.length &&
        secondaryBag.length &&
        primaryBag.length + secondaryBag.length > 3) ||
      primaryBag.length > 3 ||
      secondaryBag.length > 3
    ) {
      summaryClasses.push('cart-pop-over__line-item-list__summary--overflow')
    } else {
      summaryWithBorderClasses.push('cart-pop-over__summary--with-border')
      summaryClasses.push('cart-pop-over__line-item-list__summary--normal')
    }
    return { summaryClasses, summaryWithBorderClasses }
  }, [primaryBag, secondaryBag])

  useEffect(() => {
    groupLinesToBags(cart)
  }, [cart])

  return (
    <div className="cart-pop-over__line-item-list">
      <div className="cart-pop-over__line-item-list__container">
        {!!primaryBag?.length && (
          <div className="cart-pop-over__line-item-list__bag">
            <h3 className="cart-pop-over__line-item-list__bag-label type-label">
              {secondaryBag.length > 0 ? 'subscriptions' : 'Your Bag'}
            </h3>
            <ul className="cart-pop-over__line-item-list__items-container">
              {primaryBag.map((primaryBagItem, index) => (
                <LineItem bagItem={primaryBagItem} key={index} />
              ))}
            </ul>
          </div>
        )}
        {!!secondaryBag?.length && (
          <div className="cart-pop-over__line-item-list__bag">
            <h3 className="cart-pop-over__line-item-list__bag-label type-label">
              one-time purchases
            </h3>
            <ul className="cart-pop-over__line-item-list__items-container">
              {secondaryBag.map((secondaryBagItem, index) => (
                <LineItem bagItem={secondaryBagItem} key={index} />
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className={clsx(summaryClasses)}>
        <CartPopOverSummary cart={cart} classes={summaryWithBorderClasses} />
      </div>
    </div>
  )
}

export default LineItemList
